import React from "react";



class ProjectNode extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            drag_x:0,
            drag_y:0,
            dragging_started:false,
            dragging:false,

        };
        this.svg=null;
        this.height=50;
        this.width=50;
    }
    static getArrowPoint(center,p){
        const x=center.x;
        const y= center.y;
        const x1=p.x;
        const y1=p.y;
        const line1=x-x1-y+y1;
        const line2=-x+x1-y+y1;
        if(line1>=0 && line2>0){
            return {x:center.x+0,y:center.y+25}
        }
        if(line1<=0 && line2>0){
            return {x:center.x+25,y:center.y+0}
        }
        if(line1>=0 && line2<=0){
            return {x:center.x-25,y:center.y+0} //left
        }
        if(line1<=0 && line2<=0){
            return {x:center.x+0,y:center.y-25} //top
        }

        return {x:center.x+0,y:center.y+0}
    }
    handleDragStart=(event)=>{
        if (this.props.onClick)this.props.onClick(event);
        if(!this.props.drag_enabled)return;
        this.svg=event.target;
        const c=this.getMousePosition(event);
        this.setState({dragging_started:true,drag_x:c.x, drag_y:c.y})
    };
    handleDrag=(event)=>{
        if(!this.state.dragging_started) return;
        const c=this.getMousePosition(event);
        this.setState({dragging:true,drag_x:c.x, drag_y:c.y});
        this.props.update({
            x:c.x,
            y:c.y
        });

    };
    getMousePosition=(event) =>{
        var CTM = this.svg.getScreenCTM();
        return {
            x: (event.clientX - CTM.e) / CTM.a,
            y: (event.clientY - CTM.f) / CTM.d
        };
    };
    handleDragStop=()=>{
        if(!this.state.dragging_started) return;
        this.setState({
            dragging:false,
            drag_x:0,
            drag_y:0,
            dragging_started:false
        });
    };

    render() {
        const x=this.props.x-this.width/2;
        const y=this.props.y-this.height/2;
        return(
            <g

                onMouseDown={this.handleDragStart}
                onMouseMove={this.handleDrag}
                onMouseUp={this.handleDragStop}
            >
                <rect
                    x={x}
                    y={y}
                    width={ this.width}
                    height={ this.height}
                    stroke="#FF1493"
                    strokeWidth="3"
                    fill="#FF1493"
                    fillOpacity={0.5}

                />
                <text x={x+this.width/2} y={y+this.height/2} textAnchor="middle" className="heavy">{this.props.name}</text>
            </g>

        )
    }

}

export default ProjectNode;