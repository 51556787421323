import {CHANGE_MODE,SELECT_ITEM, CHANGE_TOOL} from "./uiActionTypes";

export const changeMode = data => ({
    type: CHANGE_MODE,
    payload: data
});
export const selectItem=id=>({
    type:SELECT_ITEM,
    payload:id
});
export const changeTool=data=>({
    type:CHANGE_TOOL,
    payload:data
});