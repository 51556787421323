import React from "react";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';

import ListItemText from '@material-ui/core/ListItemText';
import {bindActionCreators} from "redux";
import {changeMode} from "../../../redux/actions/uiActions";
import {fetchBoardIssues} from "../../../redux/actions/plugins/jira/jiraActions";

import {connect} from "react-redux";



class ProjectDetails extends React.Component{
    constructor(props) {
        super(props);
        this.state={
        };
    }
    componentDidMount() {
       this.fetchData();
    }
    fetchData=()=>{
        if(this.props.node.boardId)
            this.props.fetchBoardIssues(this.props.node.boardId);
    };
    render() {
        const {node,plugins_jira}=this.props;
        if(!plugins_jira.issues.data[this.props.node.boardId] && plugins_jira.issues.fetching===false) this.fetchData();
        const board=plugins_jira.boards.data.find((board)=>node.boardId===board.id);
        const issues=plugins_jira.issues.data[this.props.node.boardId]?plugins_jira.issues.data[this.props.node.boardId]:[];
        const resolutionRate=issues.filter((i)=>(i.fields.resolution && i.fields.resolution.name==="Done")).length/issues.length;
        const totalTimeSpent=issues.reduce((total,issue)=>(total+(issue.fields.timespent?issue.fields.timespent:0)),0)/3600;
        return(
            <>
                <h1>{node.name}</h1>
                <h5>{board?board.name:""}</h5>
                <span>{Math.round(resolutionRate*100)}% Done</span>
                <LinearProgress variant="determinate" value={resolutionRate*100} />
                <span>Hrs spent: {totalTimeSpent}</span>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p>Tasks</p>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <List dense={true}>
                            {issues.filter((i)=>(i.fields.resolution===null || i.fields.resolution.name!=="Done")).map((issue)=>(<ListItem key={issue.key}>
                                <Link target="_blank" href={`https://jira.s2.develops.hu/browse/${issue.key}`}><ListItemText
                                    primary={issue.key}
                                    secondary={issue.fields.summary}
                                /></Link>
                            </ListItem>))}
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p>Resources</p>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                       <p>MONEY</p>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

            </>
        )
    }

}


const mapStateToProps = state => ({
    ui: state.ui,
    nodes: state.nodes,
    plugins_jira: state.plugins_jira


});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeMode: changeMode,
    fetchBoardIssues: fetchBoardIssues,

}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectDetails);