import React from "react";



class ResourceNode extends React.Component{
    static r=20;
    constructor(props) {
        super(props);
        this.state={
            drag_x:0,
            drag_y:0,
            dragging_started:false,
            dragging:false,

        };
        this.svg=null;
    }
    static getArrowPoint(center, p){
        const r=ResourceNode.r;
        const m=(center.y-p.y)/(center.x-p.x);
        const u=center.x;
        const v=center.y;
        const x1=((m*m+1)*u+(u>p.x?-1:1)*r*Math.sqrt((m*m+1)))/(m*m+1);
        const y1=m*(x1-u)+v;
        return {x:x1, y:y1}
    }
    handleDragStart=(event)=>{
        if (this.props.onClick)this.props.onClick(event);
        if(!this.props.drag_enabled)return;
        this.svg=event.target;
        const c=this.getMousePosition(event);
        this.setState({dragging_started:true,drag_x:c.x, drag_y:c.y})
    };
    handleDrag=(event)=>{
        if(!this.state.dragging_started) return;
        const c=this.getMousePosition(event)
        this.setState({dragging:true,drag_x:c.x, drag_y:c.y});
        this.props.update({
            x:c.x,
            y:c.y
        });
    };
    getMousePosition=(event) =>{
        var CTM = this.svg.getScreenCTM();
        return {
            x: (event.clientX - CTM.e) / CTM.a,
            y: (event.clientY - CTM.f) / CTM.d
        };
    };
    handleDragStop=()=>{
        if(!this.state.dragging_started) return;
        this.props.update({
            x:this.state.drag_x,
            y:this.state.drag_y
        });
        this.setState({
            dragging:false,
            drag_x:0,
            drag_y:0,
            dragging_started:false
        });
    };
    render() {
        const x=this.props.x;
        const y=this.props.y;
        return(
            <g
                onMouseDown={this.handleDragStart}
                onMouseMove={this.handleDrag}
                onMouseUp={this.handleDragStop}
            >
                <circle
                    cx={x}
                    cy={y}
                    r="20"
                    stroke="#8A2BE2"
                    strokeWidth="3"
                    fill="#8A2BE2"
                    fillOpacity={0.5}

                />
                <text x={x} y={y} textAnchor="middle" className="heavy">{this.props.name}</text>

            </g>

        )
    }

}

export default ResourceNode;