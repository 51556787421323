export const initialState={
    nodes:[],
    edges:[],
    ui:{
        mode:"details",
        selectedItem:null,
        tools:{
            selected:"pointer",
            pointer:{
                firstNode:null,
                secondNode:null,
            }
        }
    },
    plugins_jira:{

            boards:{
                fetching:false,
                error:null,
                data:[]
            },
            issues:{
                fetching:false,
                error:null,
                data:{}
            }

    }
};
export const nodeDetails={
    fetched:false,
    data:{}
};