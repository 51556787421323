import * as Actions from "../actions/nodeActionTypes";
import {initialState,nodeDetails} from "./initialState";

export const nodeReducer = (state = initialState.nodes, action) => {
    switch (action.type) {
        case Actions.ADD_NODE:
            return [
                ...state,
                ...(action.payload.filter((n)=>(
                    state.findIndex(present_node=>(present_node["@rid"]===n["@rid"]))===-1
                )).map((n)=>({details:{...nodeDetails}, ...n, last_updated:action.newNode?null:new Date(), last_changed:null}))),
            ];
        case Actions.UPDATED_NODE:
            return [

            ];
        case Actions.REMOVE_NODE:
            return removeNode(state,action.id, action.safe);

        case Actions.MODIFY_NODE:
            return modifyNode(state,action.id, action.payload);
        default:
            return state
    }
};

const modifyNode=(state,id,data)=>{
    const index=state.findIndex(node=>node["@rid"]===id);
    if(index===-1)return state;
    let changedNode=state.find(node=>node["@rid"]===id);
    let tmpNew=[...state];
    tmpNew.splice(index,1);
    changedNode={...changedNode,...data, last_changed:new Date()};
    tmpNew.push(changedNode);
    return tmpNew;
};
const removeNode=(state,id,safe)=>{
    const index_remove=state.findIndex(node=>node["@rid"]===id);
    if(index_remove===-1)return state;
    let tmp_state_remove=[...state];
    tmp_state_remove.splice(index_remove,1);
    if(safe){
        let changedNode=state.find(edge=>edge["@rid"]===id);
        changedNode={...changedNode,deleted:true, last_changed:new Date()};
        tmp_state_remove.push(changedNode);
    }
    return tmp_state_remove;
};
export const getNodes=(state)=>({
    organizations:state.nodes.filter((node)=>node["@class"]==="Organization"),
    resources:state.nodes.filter((node)=>node["@class"]==="Resource"),
    projects:state.nodes.filter((node)=>node["@class"]==="Project")
});
