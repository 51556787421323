import React from "react";

import TextField from '@material-ui/core/TextField';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {bindActionCreators} from "redux";
import {changeMode} from "../../../redux/actions/uiActions";


import {connect} from "react-redux";
import {modifyNode} from "../../../redux/actions/nodeActions";
import Moment from "moment";
import Button from "@material-ui/core/Button";
import {removeResource} from "../../../redux/actions/apiNodeResource";
import {removeNode} from "../../../redux/actions/nodeActions";

class ResourceEditor extends React.Component{
    constructor(props) {
        super(props);
    }

    handleChange=(name)=>(event)=>{
        this.props.modifyNode(
            this.props.node["@rid"],
            {
                [name]:(event.target.type==="number"?parseInt(event.target.value):event.target.value)
            }
        );
    };
    handleDelete=()=>{
        this.props.removeNode(this.props.node["@rid"])
    };
    handleDateChange=(mom)=>{

        this.props.modifyNode(
            this.props.node["@rid"],
            {
                receipt_date:mom.format("YYYY-MM-DD")
            }
        );
    };
    render() {
        const {node}=this.props;
        return(
            <>
                <TextField
                    id="outlined-name"
                    label="Description"
                    value={this.props.node.description}
                    onChange={this.handleChange('description')}
                    margin="normal"
                />
                <TextField
                    id="outlined-value"
                    label="value"
                    type={"number"}
                    value={this.props.node.value}
                    onChange={this.handleChange('value')}
                    margin="normal"
                />
                <MuiPickersUtilsProvider  utils={MomentUtils}>

                        <KeyboardDatePicker
                            disableToolbar

                            format="YYYY-MM-DD"
                            margin="normal"
                            id="date-picker-inline"
                            label="Date of receipt"
                            value={Moment(this.props.node.receipt_date,"YYYY-MM-DD")}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                </MuiPickersUtilsProvider>
                <br/><Button  variant="contained" color="secondary" onClick={this.handleDelete}>DELETE</Button>
            </>
        )
    }

}


const mapStateToProps = state => ({
    ui: state.ui,
    nodes: state.nodes,



});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeMode: changeMode,
    modifyNode: modifyNode,
    removeNode:removeNode,


}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourceEditor);