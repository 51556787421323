import {API_URL} from "../../config"
import {fetchOrganizationError, fetchOrganizationPending,fetchOrganizationSuccess,putOrganizationsError,putOrganizationsPending,putOrganizationsSuccess} from "./apiActions";
import {removeNode} from "./nodeActions";
import {ADD_NODE} from "./nodeActionTypes";
import {addNode, modifyNode} from "./nodeActions";

export function fetchOrganization(id) {
    return dispatch => {
        dispatch(fetchOrganizationPending());
        fetch(`${API_URL}/organizations/${encodeURIComponent(id)}`)
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(fetchOrganizationSuccess(res));
                dispatch(modifyNode(id,
                    {
                        details:{
                            fetched:true,
                            data:{
                                travOut:res.travOut,
                                travIn:res.travIn,
                                organizationFinancialConnection:res.organizationFinancialConnection
                            }
                        }
                    }
                ));
                return res;
            })
            .catch(error => {
                dispatch(fetchOrganizationError(error));
            })
    }
}

export function updateOrganization(id,data) {
    return dispatch => {
        //dispatch(putOrganizationsPending());
        fetch(`${API_URL}/organizations/${encodeURIComponent(id)}`,{
            method:"PUT",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/json; charset=UTF-8"
            },
            body:JSON.stringify(data)
        })
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                //dispatch(putOrganizationsSuccess(res));
                return res;
            })
            .catch(error => {
                //dispatch(putOrganizationsError(error));
            })
    }
}
export function removeOrganization(id) {
    return dispatch => {
        //dispatch(putOrganizationsPending());
        fetch(`${API_URL}/organizations/${encodeURIComponent(id)}`,{
            method:"DELETE",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/json; charset=UTF-8"
            },
        })
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(removeNode(id,false));
                //dispatch(putOrganizationsSuccess(res));
                return res;
            })
            .catch(error => {
                //dispatch(putOrganizationsError(error));
            })
    }
}
export function createOrganization(data) {
    return dispatch => {
        //dispatch(putOrganizationsPending());
        fetch(`${API_URL}/organizations`,{
            method:"POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/json; charset=UTF-8"
            },
            body:JSON.stringify(data)
        })
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(removeNode(data["@rid"],false));
                dispatch(addNode(res));

                return res;
            })
            .catch(error => {
                //dispatch(putOrganizationsError(error));
            })
    }
}
