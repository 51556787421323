import {combineReducers} from "redux";
import {nodeReducer} from "./nodeReducer";
import {edgeReducer} from "./edgeReducer";
import {uiReducer} from "./uiReducer";

/*PLUGINS*/
import {jiraReducer} from "./plugins/jira/jiraReducer";
/*END PLUGINS*/
// plugins:{
//     jira:jiraReducer
// }
export default combineReducers({
    nodes:nodeReducer,
    edges:edgeReducer,
    ui:uiReducer,
    plugins_jira:jiraReducer,
})