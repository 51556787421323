import React from "react";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import {bindActionCreators} from "redux";
import {changeMode} from "../../../redux/actions/uiActions";

import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import {modifyNode} from "../../../redux/actions/nodeActions";
import {fetchBoards} from "../../../redux/actions/plugins/jira/jiraActions";
import {updateProject, removeProject} from "../../../redux/actions/apiNodeProject";
import {removeNode} from "../../../redux/actions/nodeActions";

class ProjectEditor extends React.Component{
    constructor(props) {
        super(props);


    }
    componentDidMount() {

    }

    handleChange=(name)=>(event)=>{
        this.props.modifyNode(
            this.props.node["@rid"],
            {
                [name]:event.target.value,
            }
        );
    };
    handleDelete=()=>{
        this.props.removeNode(this.props.node["@rid"]);
    };
    render() {
        const menu= this.props.plugins_jira.boards.data.map((board)=><MenuItem key={board.id} value={board.id}>{board.name}</MenuItem>);
        return(
            <>
                <TextField
                    id="outlined-name"
                    label="Name"
                    value={this.props.node.name}
                    onChange={this.handleChange('name')}
                    margin="normal"
                /><br/>
                <Select
                    value={this.props.node.boardId}
                    onChange={this.handleChange("boardId")}
                    inputProps={{
                        name: 'age',
                        id: '1',
                    }}
                >
                    {menu}

                </Select>
                <br/><Button  variant="contained" color="secondary" onClick={this.handleDelete}>DELETE</Button>
            </>
        )
    }

}


const mapStateToProps = state => ({
    ui: state.ui,
    nodes: state.nodes,
    plugins_jira:state.plugins_jira


});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeMode: changeMode,
    modifyNode: modifyNode,
    updateProject: updateProject,
    removeNode:removeNode,


}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectEditor);