import React from "react";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import {bindActionCreators} from "redux";
import {changeMode} from "../../../redux/actions/uiActions";
import Moment from "moment";
import {connect} from "react-redux";



class ResourceDetails extends React.Component{
    constructor(props) {
        super(props);
        this.state={
        };


    }

    render() {
        const {node}=this.props;
        console.log(node)
        return(
            <>
                <p>{node.description}</p>
                <p>{
                    node.value && node.value.toLocaleString('HU', {
                        style: 'currency',
                        currency: 'HUF',
                    })
                    }
                </p>
                <p>
                    {node.receipt_date?Moment(node.receipt_date,"YYYY-MM-DD").format("YYYY-MM-DD").toString():""}
                </p>
            </>
        )
    }

}


const mapStateToProps = state => ({
    ui: state.ui,
    nodes: state.nodes,



});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeMode: changeMode,

}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourceDetails);