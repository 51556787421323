import * as Actions from "../../../actions/plugins/jira/jiraActionTypes";
import {initialState} from "../../initialState";

export const jiraReducer = (state = initialState.plugins_jira, action) => {
    switch (action.type) {
        case Actions.FETCH_BOARDS_PENDING:
            return{
                ...state,
               boards:{
                    ...state.boards,
                   fetching:true,
                   error:null
               }
            };
        case Actions.FETCH_BOARDS_ERROR:

            return{
                ...state,
                boards:{
                    ...state.boards,
                    fetching:false,
                    error:action.payload
                }
            };
        case Actions.FETCH_BOARDS_SUCCESS:

            return{
                ...state,
                boards:{
                    data:action.payload,
                    fetching:false,
                    error:null
                }
            };

        case Actions.FETCH_BOARD_ISSUES_ERROR:
            return{
                ...state,
                issues:{
                    ...state.issues,
                    error:action.payload,
                    fetching:false
                }
            };
        case Actions.FETCH_BOARD_ISSUES_PENDING:
            return{
                ...state,
                issues:{
                    ...state.issues,
                    error:null,
                    fetching:true
                }
            };
        case Actions.FETCH_BOARD_ISSUES_SUCCESS:
            return{
                ...state,
                issues:{
                    data:{
                        ...state.issues.data,
                        [action.boardId]:action.payload
                    },
                    error:null,
                    fetching:false
                }
            };
        default:
            return state
    }
};
