import React from 'react';
import logo from './logo.svg';
import './App.css';
import Graph from "./components/Graph/Graph";
import Sidebar from "./components/Sidebar/Sidebar";

function App() {
  return (
    <div className="App">
        <div className="container">
            <div className="Graph">
                <Graph/>
            </div>
            <div className="Sidebar">
                <Sidebar/>
            </div>
        </div>


    </div>
  );
}

export default App;
