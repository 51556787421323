import React from "react";
import OrganizationNode from "../GraphNode/OrganizationNode"
import ResourceNode from "../GraphNode/ResourceNode";
import ProjectNode from "../GraphNode/ProjectNode";
import ProjectsEdge from "../GraphEdge/ProjectsEdge";
import FinanceEdge from "../GraphEdge/FinanceEdge";
import Snackbar from '@material-ui/core/Snackbar';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import ProjectIcon from '@material-ui/icons/Assignment';
import OrganizationIcon from '@material-ui/icons/Apartment';
import ResourceIcon from '@material-ui/icons/MonetizationOn';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addEdge, removeEdge} from "../../redux/actions/edgeActions";
import {addNode, removeNode} from "../../redux/actions/nodeActions";
import {getNodes} from "../../redux/reducers/nodeReducer";
import {getEdges} from "../../redux/reducers/edgeReducer";
import {fetchNodes} from "../../redux/actions/apiNodes";
import {fetchEdges} from "../../redux/actions/apiEdges";
import {modifyNode} from "../../redux/actions/nodeActions";
import {selectItem} from "../../redux/actions/uiActions";
import {fetchBoards} from "../../redux/actions/plugins/jira/jiraActions";
import {updateOrganization, createOrganization, removeOrganization} from "../../redux/actions/apiNodeOrganizations";
import {updateProject, createProject, removeProject} from "../../redux/actions/apiNodeProject";
import {updateResource, createResource, removeResource} from "../../redux/actions/apiNodeResource";
import {createEdge, removeEdge as removeEdgeApi} from "../../redux/actions/apiEdges";
import {API_URL} from "../../config"
import openSocket from 'socket.io-client';
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import {UncontrolledReactSVGPanZoom} from 'react-svg-pan-zoom';
import {AutoSizer} from "react-virtualized";

class Graph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            snackBarOpen: false,
            snackBarData: null,
            speedDialOpen: false,
        };
        this.Viewer = null;

    }

    componentDidMount() {
        this.props.fetchNodes();
        this.props.fetchEdges();
        this.props.fetchBoards();
        const socket = openSocket(API_URL);
        socket.on("node-change", (node) => {
            this.setState({
                snackBarOpen: true, snackBarData: {
                    message: "Updated node " + node.id
                }
            });
            this.props.modifyNode(node.id, node);
        });
        socket.on("node-new", (node) => {
            this.setState({
                snackBarOpen: true, snackBarData: {
                    message: "New node " + node[0]["@rid"]
                }
            });
            this.props.addNode(node);
        });
        socket.on("node-remove", (id) => {
            this.setState({
                snackBarOpen: true, snackBarData: {
                    message: "Removed node " + id
                }
            });
            this.props.removeNode(id, false);
        });

        socket.on("edge-remove", (id) => {
            this.setState({
                snackBarOpen: true, snackBarData: {
                    message: "Removed edge " + id
                }
            });
            this.props.removeEdge(id, false);
        });
        socket.on("edge-new", (edge) => {
            this.setState({
                snackBarOpen: true, snackBarData: {
                    message: "Removed edge " + edge[0]["@rid"]
                }
            });
            this.props.addEdge(edge);
        });

    }

    nodeUpdate = (id) => (data) => {
        this.props.modifyNode(id, data);
    };
    handleNodeClick = (id) => (e) => {

        if (this.props.ui.tools.pointer.firstNode !== null && this.props.ui.tools.pointer.secondNode === null && this.props.ui.tools.pointer.firstNode !== id) {
            const financer = this.props.raw_nodes.find((n) => {
                return (n["@rid"] === id || n["@rid"] === this.props.ui.tools.pointer.firstNode) && n["@class"] === "Resource"
            });
            this.props.addEdge([
                {
                    out: this.props.ui.tools.pointer.firstNode,
                    in: id,
                    "@class": financer ? "financer" : "projects",
                    "@rid": Math.random()
                }
            ], true);
        }
        this.props.selectItem(id);
    };
    handleEdgeClick = (id) => (e) => {
        if (e.button === 2 && this.props.ui.mode === "editor" && this.props.ui.tools.selected === "edit-edge") {
            this.props.removeEdge(id);
        }
    };
    handleNewNode = (type) => () => {
        console.log(type);
        this.props.addNode([{
            "@class": type,
            "@rid": Math.random(),
            x: 20,
            y: 20,
        }], true);
    };
    handleSave = () => {
        const updateEdges = this.props.raw_edges.filter((edge) => (edge.last_updated === null || edge.last_changed > edge.last_updated || edge.deleted));
        const updateNodes = this.props.raw_nodes.filter((node) => (node.last_updated === null || node.last_changed > node.last_updated || node.deleted));
        updateNodes.map((node) => {
            switch (node["@class"]) {
                case "Project":
                    if (node.deleted)
                        this.props.removeProject(node["@rid"]);
                    else if (node.last_updated == null)
                        this.props.createProject(node);
                    else
                        this.props.updateProject(node["@rid"], node);
                    break;
                case "Organization":
                    if (node.deleted)
                        this.props.removeOrganization(node["@rid"]);
                    else if (node.last_updated == null)
                        this.props.createOrganization(node);
                    else
                        this.props.updateOrganization(node["@rid"], node);
                    break;
                case "Resource":
                    if (node.deleted)
                        this.props.removeResource(node["@rid"]);
                    else if (node.last_updated == null)
                        this.props.createResource(node);
                    else
                        this.props.updateResource(node["@rid"], node);
                    break;
            }

        });
        updateEdges.map((edge) => {
            if (edge.deleted)
                this.props.removeEdgeApi(edge["@rid"]);
            else
                this.props.createEdge(edge);
        });

    };

    render() {
        const editorMode = this.props.ui.mode === "editor";
        const dragTool = editorMode && this.props.ui.tools.selected === "move-node";

        return (
            <>
                <AutoSizer>
                    {({width, height}) => width === 0 || height === 0 ? null : (
                            <UncontrolledReactSVGPanZoom
                                width={width} height={height}
                                ref={Viewer => this.Viewer = Viewer}

                                onClick={event => console.log('click', event.x, event.y, event.originalEvent)}>

                                <svg onContextMenu={e => {
                                    e.preventDefault();
                                }} height="1280" width="1920">
                                    {this.props.edges.projects.map((edge) => {
                                        const inNode = this.props.raw_nodes.find((node) => node["@rid"] === edge.in);
                                        const outNode = this.props.raw_nodes.find((node) => node["@rid"] === edge.out);
                                        if (!(inNode && outNode) || (inNode.deleted || outNode.deleted) || edge.deleted) return (<></>);

                                        return (<ProjectsEdge
                                            key={edge["@rid"]}
                                            inNode={inNode}
                                            outNode={outNode}

                                            onClick={this.handleEdgeClick(edge["@rid"])}
                                        />);

                                    })}

                                    {this.props.edges.financer.map((edge) => {
                                        const inNode = this.props.raw_nodes.find((node) => node["@rid"] === edge.in);
                                        const outNode = this.props.raw_nodes.find((node) => node["@rid"] === edge.out);
                                        if (!(inNode && outNode) || (inNode.deleted || outNode.deleted) || edge.deleted) return (<></>);

                                        return (<FinanceEdge
                                            key={edge["@rid"]}
                                            inNode={inNode}
                                            outNode={outNode}
                                            onClick={this.handleEdgeClick(edge["@rid"])}
                                        />);

                                    })}

                                    {this.props.nodes.organizations.filter((n) => !n.deleted).map(node => <OrganizationNode
                                        key={node["@rid"]}
                                        drag_enabled={dragTool}
                                        update={this.nodeUpdate(node["@rid"])}
                                        x={node.x}
                                        y={node.y}
                                        name={node.name}
                                        onClick={this.handleNodeClick(node["@rid"])}
                                    />)}
                                    {this.props.nodes.resources.filter((n) => !n.deleted).map(node => <ResourceNode
                                        key={node["@rid"]}
                                        drag_enabled={dragTool}
                                        update={this.nodeUpdate(node["@rid"])}
                                        x={node.x}
                                        y={node.y}
                                        name={node.description}
                                        onClick={this.handleNodeClick(node["@rid"])}
                                    />)}
                                    {this.props.nodes.projects.filter((n) => !n.deleted).map(node => <ProjectNode
                                        key={node["@rid"]}
                                        drag_enabled={dragTool}
                                        update={this.nodeUpdate(node["@rid"])}
                                        x={node.x}
                                        y={node.y}
                                        name={node.name}
                                        onClick={this.handleNodeClick(node["@rid"])}
                                    />)}

                                </svg>
                            </UncontrolledReactSVGPanZoom>
                        )}
                        </ AutoSizer>
                    {editorMode && <SpeedDial
                        ariaLabel="SpeedDial example"
                        className={"fab"}
                        hidden={false}
                        icon={<SaveIcon/>}
                        onClose={() => {
                        this.setState({speedDialOpen: false})
                    }}
                        onOpen={() => {
                        this.setState({speedDialOpen: true})
                    }}
                        onClick={this.handleSave}
                        open={this.state.speedDialOpen}
                        direction={"up"}
                        >
                        <SpeedDialAction
                        title={"New Resource"}
                        key={"new_resource"}
                        icon={<ResourceIcon/>}
                        tooltipTitle={"New Resource"}
                        onClick={this.handleNewNode("Resource")}
                        />
                        <SpeedDialAction
                        title={"New Project"}
                        key={"new_project"}
                        icon={<ProjectIcon/>}
                        tooltipTitle={"New Project"}
                        onClick={this.handleNewNode("Project")}
                        />
                        <SpeedDialAction
                        title={"New Organization"}
                        key={"new_organization"}
                        icon={<OrganizationIcon/>}
                        tooltipTitle={"New Organization"}
                        onClick={this.handleNewNode("Organization")}
                        />

                        </SpeedDial>}
                    {/*{editorMode && <Fab color="primary" onClick={this.handleSave} aria-label="add" className={"fab"}>*/}
                    {/*    <SaveIcon />*/}
                    {/*</Fab>}*/}
                        <Snackbar
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                        open={this.state.snackBarOpen}
                        autoHideDuration={6000}
                        onClose={() => {
                        this.setState({snackBarOpen: false});
                    }}
                        message={<span>{this.state.snackBarData ? this.state.snackBarData.message : ""}</span>}
                        />

                        </>

                        )
                    }

                    }


                    const mapStateToProps = state => ({
                    nodes: getNodes(state),
                    raw_nodes: state.nodes,
                    edges: getEdges(state),
                    raw_edges: state.edges,
                    ui: state.ui,

                });

                    const mapDispatchToProps = dispatch => bindActionCreators({
                    fetchNodes: fetchNodes,
                    fetchEdges: fetchEdges,
                    fetchBoards: fetchBoards,
                    modifyNode: modifyNode,
                    selectItem: selectItem,
                    addEdge: addEdge,
                    createEdge: createEdge,
                    removeEdge: removeEdge,
                    addNode: addNode,
                    removeNode: removeNode,
                    updateOrganization: updateOrganization,
                    updateProject: updateProject,
                    updateResource: updateResource,
                    createOrganization: createOrganization,
                    createProject: createProject,
                    createResource: createResource,
                    removeResource: removeResource,
                    removeOrganization: removeOrganization,
                    removeProject: removeProject,
                    removeEdgeApi: removeEdgeApi
                }, dispatch);

                    export default connect(
                    mapStateToProps,
                    mapDispatchToProps
                    )(Graph);