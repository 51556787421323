import React from "react";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import ControlCameraOutlinedIcon from '@material-ui/icons/ControlCameraOutlined';
import TrendingFlatOutlinedIcon from '@material-ui/icons/TrendingFlatOutlined';
import MouseOutlinedIcon from '@material-ui/icons/MouseOutlined';
import ProjectEditor from "./Project/ProjectEditor";
import OrganizationEditor from "./Organization/OrganizationEditor";
import ResourceEditor from "./Resource/ResourceEditor";

import ProjectDetails from "./Project/ProjectDetails";
import OrganizationDetails from "./Organization/OrganizationDetails";
import ResourceDetails from "./Resource/ResourceDetails";

import {bindActionCreators} from "redux";
import {changeMode} from "../../redux/actions/uiActions";
import {changeTool} from "../../redux/actions/uiActions";

import {connect} from "react-redux";
import {TableSortLabel} from "@material-ui/core";



class Sidebar extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            value:0,
        };


    }
    modeMapper=(value,reverse)=>{
        const modes=["details","editor"];
        if(reverse){
            return  modes[value];
        }
        return modes.indexOf(value);

    };
    handleChangeMode=(event, value)=>{
        this.props.changeMode(this.modeMapper(value,true));
    };
    handleEditorTool=(event)=>{
       this.props.changeTool(event.target.value);
    };
    render() {
        let detailedNode=null;
        let Details=null;
        let Editor=null;
        if(this.props.ui.selectedItem!==null){
            detailedNode=this.props.nodes.find((node)=>node["@rid"]===this.props.ui.selectedItem);
            if(detailedNode)
            switch(detailedNode["@class"]){
                case "Project": Details=ProjectDetails; Editor=ProjectEditor;break;
                case "Organization":Details=OrganizationDetails; Editor=OrganizationEditor;break;
                case "Resource": Details=ResourceDetails; Editor=ResourceEditor; break;
            }
        }

        return(
            <Paper square className={"Sidebar-Paper"}>
                <Tabs
                    value={this.modeMapper(this.props.ui.mode)}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleChangeMode}
                    aria-label="disabled tabs example"
                >
                    <Tab label="Details" />
                    <Tab label="Editor" />
                </Tabs>
                <hr/>

                <Box hidden={this.modeMapper(this.props.ui.mode)===0?false:true}>
                    {detailedNode && <Details key={detailedNode["@rid"]} node={detailedNode}/> || "Please select a node."}
                </Box>
                <Box hidden={this.modeMapper(this.props.ui.mode)===1?false:true}>
                    <Radio
                        checked={this.props.ui.tools.selected==="pointer"}
                        onChange={this.handleEditorTool}
                        value="pointer"
                        icon={<MouseOutlinedIcon/>}
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <Radio
                        checked={this.props.ui.tools.selected==="move-node"}
                        onChange={this.handleEditorTool}
                        value="move-node"
                        name="radio-button-demo"
                        icon={<ControlCameraOutlinedIcon/>}
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <Radio
                        checked={this.props.ui.tools.selected==="edit-edge"}
                        onChange={this.handleEditorTool}
                        value="edit-edge"
                        icon={<TrendingFlatOutlinedIcon/>}
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'A' }}
                    />

                    <hr/>
                    {detailedNode && <Editor key={detailedNode["@rid"]} node={detailedNode}/> || "Please select a node."}
                </Box>
            </Paper>

        )
    }

}


const mapStateToProps = state => ({
    ui: state.ui,
    nodes: state.nodes,



});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeMode: changeMode,
    changeTool: changeTool,

}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Sidebar);