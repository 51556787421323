import {API_URL} from "../../../../config"
import * as Actions from "./jiraActionTypes";
export const fetchBoards= () =>{
    return dispatch => {
        dispatch(fetchBoardsPending());
        fetch(`${API_URL}/plugins/jira/boards`)
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(fetchBoardsSuccess(res.values));
                return res;
            })
            .catch(error => {
                dispatch(fetchBoardsError(error));
            })
    }
};
export const fetchBoardIssues= (id) =>{
    return dispatch => {
        dispatch(fetchBoardIssuesPending());
        fetch(`${API_URL}/plugins/jira/boards/${id}`)
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(fetchBoardIssuesSuccess(id,res.issues));
                return res;
            })
            .catch(error => {
                dispatch(fetchBoardIssuesError(error));
            })
    }
};



const fetchBoardsPending=()=>({
    type:Actions.FETCH_BOARDS_PENDING
});
const fetchBoardsSuccess=(res)=>({
    type:Actions.FETCH_BOARDS_SUCCESS,
    payload:res
});
const fetchBoardsError=(err)=>({
    type:Actions.FETCH_BOARDS_ERROR,
    payload:err
});

const fetchBoardIssuesPending=()=>({
    type:Actions.FETCH_BOARD_ISSUES_PENDING
});
const fetchBoardIssuesSuccess=(id,res)=>({
    type:Actions.FETCH_BOARD_ISSUES_SUCCESS,
    payload:res,
    boardId:id
});
const fetchBoardIssuesError=(err)=>({
    type:Actions.FETCH_BOARD_ISSUES_ERROR,
    payload:err
});