import * as Actions from "../actions/uiActionTypes";
import {initialState} from "./initialState";

export const uiReducer = (state = initialState.ui, action) => {
    switch (action.type) {
        case Actions.CHANGE_MODE:
            return {...state,mode:action.payload};
        case Actions.SELECT_ITEM:
            let additional={};
            if(state.tools.selected==="edit-edge"){
                if(state.tools.pointer.firstNode===null || state.tools.pointer.secondNode!==null){
                    additional={
                        pointer:{
                            ...state.tools.pointer,
                            firstNode: action.payload,
                            secondNode:null,
                        }
                    }
                }else{
                    additional={
                        pointer:{
                            ...state.tools.pointer,
                            secondNode: action.payload,
                        }
                    }
                }

            }
            return {
                ...state,
                tools:{
                    ...state.tools,
                    ...additional
                },
                selectedItem:action.payload
            };
        case Actions.CHANGE_TOOL:
            return {...state, tools:{
                    ...state.tools,
                    selected:action.payload,
                    pointer:initialState.ui.tools.pointer
                }};
        default:
            return state
    }
};

