import {API_URL} from "../../config"
import {fetchNodesError, fetchNodesPending,fetchNodesSuccess} from "./apiActions";
import {addNode} from "./nodeActions";

export const fetchNodes= () =>{
    return dispatch => {
        dispatch(fetchNodesPending());
        fetch(`${API_URL}/graph/nodes`)
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(fetchNodesSuccess(res));
                dispatch(addNode(res));
                return res;
            })
            .catch(error => {
                dispatch(fetchNodesError(error));
            })
    }
};

