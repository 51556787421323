import {ADD_EDGE,REMOVE_EDGE,MODIFY_EDGE} from "./edgeActionTypes";
export const addEdge=(data,newEdge)=>({
    type:ADD_EDGE,
    payload:data,
    newEdge:!!newEdge
});
export const removeEdge=(id,safe)=>({
    type:REMOVE_EDGE,
    id:id,
    safe:safe?safe:true

});