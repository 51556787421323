export const API_FETCHING_ORGANIZATIONS_PENDING="API_FETCHING_ORGANIZATIONS_PENDING";
export const API_FETCHING_ORGANIZATIONS_SUCCESS="API_FETCHING_ORGANIZATIONS_SUCCESS";
export const API_FETCHING_ORGANIZATIONS_ERROR="API_FETCHING_ORGANIZATIONS_ERROR";

export const API_FETCHING_ORGANIZATION_PENDING="API_FETCHING_ORGANIZATION_PENDING";
export const API_FETCHING_ORGANIZATION_SUCCESS="API_FETCHING_ORGANIZATION_SUCCESS";
export const API_FETCHING_ORGANIZATION_ERROR="API_FETCHING_ORGANIZATION_ERROR";

export const API_FETCHING_NODES_PENDING="API_FETCHING_NODES_PENDING";
export const API_FETCHING_NODES_SUCCESS="API_FETCHING_NODES_SUCCESS";
export const API_FETCHING_NODES_ERROR="API_FETCHING_NODES_ERROR";

export const API_FETCHING_EDGES_PENDING="API_FETCHING_EDGES_PENDING";
export const API_FETCHING_EDGES_SUCCESS="API_FETCHING_EDGES_SUCCESS";
export const API_FETCHING_EDGES_ERROR="API_FETCHING_EDGES_ERROR";

//export const SOCKET_NEW_DATA