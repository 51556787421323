import {ADD_NODE,REMOVE_NODE,MODIFY_NODE} from "./nodeActionTypes";
export const addNode=(data,newNode)=>({
    type:ADD_NODE,
    payload:data,
    newNode:!!newNode,
});
export const modifyNode=(id,data)=>({
    type:MODIFY_NODE,
    payload:data,
    id:id,
});

export const removeNode=(id,safe)=>({
    type:REMOVE_NODE,
    id:id,
    safe:safe?safe:true,
});