import * as Actions from "../actions/edgeActionTypes";
import {initialState} from "./initialState";

export const edgeReducer = (state = initialState.edges, action) => {
    switch (action.type) {
        case Actions.ADD_EDGE:
            return [
                ...state,
                ...(action.payload.map(edge=>({...edge, last_updated:action.newEdge?null:new Date(), last_changed:null})))
            ];
        case Actions.REMOVE_EDGE:
            const index=state.findIndex(edge=>edge["@rid"]===action.id);
            if(index===-1)return state;

            let tmpNew=[...state];
            tmpNew.splice(index,1);
            if(action.safe){
                let changedEdge=state.find(edge=>edge["@rid"]===action.id);
                changedEdge={...changedEdge,deleted:true, last_changed:new Date()};
                tmpNew.push(changedEdge);
            }
            return tmpNew;
        case Actions.MODIFY_EDGE:
            return [];
        default:
            return state
    }
};

export const getEdges=(state)=>({
    financer:state.edges.filter((edge)=>edge["@class"]==="financer"),
    projects:state.edges.filter((edge)=>edge["@class"]==="projects"),
});
