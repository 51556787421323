import React from "react";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import {bindActionCreators} from "redux";
import {changeMode} from "../../../redux/actions/uiActions";
import {fetchOrganization} from "../../../redux/actions/apiNodeOrganizations";

import {connect} from "react-redux";



class OrganizationDetails extends React.Component{
    constructor(props) {
        super(props);
        this.state={
        };


    }
    componentDidMount() {
        this.fetchDetails();
    }
    fetchDetails=()=>{
        if(!this.props.node.details.fetched)
            this.props.fetchOrganization(this.props.node["@rid"]);
    };

    render() {
        const {node}=this.props;
        return(
            <>
                <h1>{node.name}</h1>
                {node.details.fetched && node.details.data.organizationFinancialConnection.map((org)=>(
                    <p>{org.name}</p>
                ))}
                <span>
                    Inflow: {node.details.fetched && node.details.data.travIn.filter((n)=>n["@class"]==="Resource").reduce((income,n)=>(income+n.value),0).toLocaleString('HU', {
                        style: 'currency',
                        currency: 'HUF',
                    })}
                </span><br/>
                <span>
                    Outflow: {node.details.fetched && node.details.data.travOut.filter((n)=>n["@class"]==="Resource").reduce((income,n)=>(income+n.value),0).toLocaleString('HU', {
                        style: 'currency',
                        currency: 'HUF',
                    })}
                </span>
            </>
        )
    }

}


const mapStateToProps = state => ({
    ui: state.ui,
    nodes: state.nodes,



});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeMode: changeMode,
    fetchOrganization:fetchOrganization,

}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationDetails);