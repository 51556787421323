import {API_URL} from "../../config"
import {fetchEdgesError, fetchEdgesPending,fetchEdgesSuccess} from "./apiActions";
import {addEdge,removeEdge as removeEdgeFromStore} from "./edgeActions";

export const fetchEdges= () =>{
    return dispatch => {
        dispatch(fetchEdgesPending());
        fetch(`${API_URL}/graph/edges`)
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(fetchEdgesSuccess(res));
                dispatch(addEdge(res));
                return res;
            })
            .catch(error => {
                dispatch(fetchEdgesError(error));
            })
    }
};

export const createEdge= (data) =>{
    return dispatch => {
        fetch(`${API_URL}/graph/edges/${data["@class"]}`,{
            method:"POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/json; charset=UTF-8"
            },
            body:JSON.stringify(data)
        })
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(addEdge(res));
                dispatch(removeEdge(data["@rid"]));
                return res;
            })
            .catch(error => {
            })
    }
};

export const removeEdge= (id) =>{
    return dispatch => {
        fetch(`${API_URL}/graph/edges/${encodeURIComponent(id)}`,{
            method:"DELETE",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/json; charset=UTF-8"
            },
        })
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(removeEdgeFromStore(id,false));
                return res;
            })
            .catch(error => {
            })
    }
};
