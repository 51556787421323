import React from "react";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {bindActionCreators} from "redux";
import {changeMode} from "../../../redux/actions/uiActions";
import {modifyNode} from "../../../redux/actions/nodeActions";
import {updateOrganization,removeOrganization} from "../../../redux/actions/apiNodeOrganizations";
import {removeNode} from "../../../redux/actions/nodeActions";

import {connect} from "react-redux";



class OrganizationEditor extends React.Component{
    constructor(props) {
        super(props);
    }
    handleChange=(name)=>(event)=>{
        this.props.modifyNode(
            this.props.node["@rid"],
            {
                [name]:event.target.value,
            }
        );
    };
    handleDelete=()=>{
        this.props.removeNode(this.props.node["@rid"])
    };
    render() {
        const {node}=this.props;
        return(
            <>
                <TextField
                    id="outlined-name"
                    label="Name"
                    value={this.props.node.name}
                    onChange={this.handleChange('name')}
                    margin="normal"
                />
                <br/><Button  variant="contained" color="secondary" onClick={this.handleDelete}>DELETE</Button>

            </>
        )
    }

}


const mapStateToProps = state => ({
    ui: state.ui,
    nodes: state.nodes,



});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeMode: changeMode,
    modifyNode: modifyNode,
    removeNode: removeNode,
    updateOrganization:updateOrganization

}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationEditor);