import {API_URL} from "../../config";
import {addNode, removeNode} from "./nodeActions";

export function updateProject(id,data) {
    return dispatch => {
        //dispatch(putOrganizationsPending());
        fetch(`${API_URL}/projects/${encodeURIComponent(id)}`,{
            method:"PUT",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/json; charset=UTF-8"
            },
            body:JSON.stringify(data)
        })
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                //dispatch(putOrganizationsSuccess(res));
                return res;
            })
            .catch(error => {
                //dispatch(putOrganizationsError(error));
            })
    }
}

export function createProject(data) {
    return dispatch => {
        //dispatch(putOrganizationsPending());
        fetch(`${API_URL}/projects/`,{
            method:"POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/json; charset=UTF-8"
            },
            body:JSON.stringify(data)
        })
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                //dispatch(putOrganizationsSuccess(res));
                dispatch(removeNode(data["@rid"],false));
                dispatch(addNode(res));
                return res;
            })
            .catch(error => {
                //dispatch(putOrganizationsError(error));
            })
    }
}
export function removeProject(id) {
    return dispatch => {
        //dispatch(putOrganizationsPending());
        fetch(`${API_URL}/projects/${encodeURIComponent(id)}`,{
            method:"DELETE",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/json; charset=UTF-8"
            },

        })
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(removeNode(id,false));
                //dispatch(putOrganizationsSuccess(res));
                return res;
            })
            .catch(error => {
                //dispatch(putOrganizationsError(error));
            })
    }
}