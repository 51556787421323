import React from "react";
import ProjectNode from "../GraphNode/ProjectNode";
import OrganizationNode from "../GraphNode/OrganizationNode";
import ResourceNode from "../GraphNode/ResourceNode";


class FinanceEdge extends React.Component{
    constructor(props) {
        super(props);
        this.state={

        };

    }

    handleClick=(e)=>{
        if( (typeof this.props.onClick) ==="function") this.props.onClick(e);
    };
    render() {
        let p;
        switch(this.props.inNode["@class"]){
            case "Organization":
                p=OrganizationNode.getArrowPoint(this.props.inNode,this.props.outNode);
                this.x1=p.x;
                this.y1=p.y;
                break;
            case "Resource":
                p=ResourceNode.getArrowPoint(this.props.inNode,this.props.outNode);
                this.x1=p.x;
                this.y1=p.y;
                break;
            case "Project":
                p=ProjectNode.getArrowPoint(this.props.inNode,this.props.outNode);
                this.x1=p.x;
                this.y1=p.y;

                break;
            default:
                this.x1=this.props.inNode.x;
                this.y1=this.props.inNode.y;
                break;
        }
        switch(this.props.outNode["@class"]){
            case "Organization":
                p=OrganizationNode.getArrowPoint(this.props.outNode,this.props.inNode);
                this.x2=p.x;
                this.y2=p.y;
                break;
            case "Resource":
                p=ResourceNode.getArrowPoint(this.props.outNode,this.props.inNode);
                this.x2=p.x;
                this.y2=p.y;
                break;
            case "Project":
                p=ProjectNode.getArrowPoint(this.props.outNode,this.props.inNode);
                this.x2=p.x;
                this.y2=p.y;

                break;
            default:
                this.x2=this.props.inNode.x;
                this.y2=this.props.inNode.y;
                break;
        }
        return(
            <g onMouseDown={this.handleClick}>
                <defs>
                    <marker id="finance_edge_arrow" markerWidth="45" markerHeight="45" refX="20" refY="7.5" orient="auto" markerUnits="strokeWidth">
                        <path d="M0,0 L0,15 20,7 z" fill="#e57373" />
                    </marker>
                </defs>
                <line
                    x1={this.x2}
                    y1={this.y2}
                    x2={this.x1}
                    y2={this.y1}
                    stroke="#e57373"
                    markerEnd="url(#finance_edge_arrow)"
                />

                <line
                    x1={this.x2}
                    y1={this.y2}
                    x2={this.x1}
                    y2={this.y1}
                    stroke="#e57373"
                    strokeOpacity={0.0}
                    strokeWidth={25}
                />
            </g>

        )
    }

}

export default FinanceEdge;