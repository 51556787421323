import {
    API_FETCHING_NODES_ERROR,
    API_FETCHING_NODES_PENDING,
    API_FETCHING_NODES_SUCCESS,
    API_FETCHING_EDGES_ERROR,
    API_FETCHING_EDGES_PENDING,
    API_FETCHING_EDGES_SUCCESS,
    API_FETCHING_ORGANIZATIONS_PENDING,
    API_FETCHING_ORGANIZATIONS_ERROR,
    API_FETCHING_ORGANIZATIONS_SUCCESS,
    API_FETCHING_ORGANIZATION_PENDING,
    API_FETCHING_ORGANIZATION_ERROR,
    API_FETCHING_ORGANIZATION_SUCCESS,
} from "./apiActionTypes";

/* organizations*/
export const fetchOrganizationsPending =()=>({
    type:API_FETCHING_ORGANIZATIONS_PENDING
});
export const fetchOrganizationsSuccess =(organizations)=>({
    type:API_FETCHING_ORGANIZATIONS_SUCCESS,
    payload:organizations
});
export const fetchOrganizationsError =(err)=>({
    type:API_FETCHING_ORGANIZATIONS_ERROR,
    payload:err
});
export const fetchOrganizationPending =()=>({
    type:API_FETCHING_ORGANIZATION_PENDING
});
export const fetchOrganizationSuccess =(organizations)=>({
    type:API_FETCHING_ORGANIZATION_SUCCESS,
    payload:organizations
});
export const fetchOrganizationError =(err)=>({
    type:API_FETCHING_ORGANIZATION_ERROR,
    payload:err
});
export const putOrganizationsPending =()=>({

});
export const putOrganizationsSuccess =()=>({

});
export const putOrganizationsError =()=>({

});
//***Nodes***//
export const fetchNodesPending =()=>({
    type:API_FETCHING_NODES_PENDING
});
export const fetchNodesSuccess =(nodes)=>({
    type:API_FETCHING_NODES_SUCCESS,
    payload:nodes
});
export const fetchNodesError =(error)=>({
    type:API_FETCHING_NODES_ERROR,
    payload:error
});
//***edges***//
export const fetchEdgesPending =()=>({
    type:API_FETCHING_EDGES_PENDING
});
export const fetchEdgesSuccess =(nodes)=>({
    type:API_FETCHING_EDGES_SUCCESS,
    payload:nodes
});
export const fetchEdgesError =(error)=>({
    type:API_FETCHING_EDGES_ERROR,
    payload:error
});



